<template>
  <div>
    <vx-card :is-refresh="isLoading">
      <vs-tabs alignment="fixed" v-model="activeTab">
        <div class="my-2">
          <vs-tab label="Aktifitas Marketing"
                  icon-pack="feather"
                  :icon="errors.tabAktifitas.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabAktifitas.isError ? 'danger' : null">
            <TabAktifitas :initData="initDataTabAktifitas"/>
          </vs-tab>

          <vs-tab label="Data Customer Prospek"
                  icon-pack="feather"
                  :icon="errors.tabCustomer.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabCustomer.isError ? 'danger' : null">
            <TabCustomer :initData="initDataTabCustomer"/>
          </vs-tab>

          <vs-tab label="Pekerjaan"
                  icon-pack="feather"
                  :icon="errors.tabPekerjaan.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabPekerjaan.isError ? 'danger' : null">
            <TabPekerjaan :initData="initDataTabPekerjaan"/>
          </vs-tab>
        </div>
      </vs-tabs>
    </vx-card>

    <div class="vx-card mt-5" v-if="activeTab === 2">
      <div class="w-full flex justify-end">
        <vs-button :disabled="isLoading" icon-pack="feather" icon="icon-save" class="w-full" color="primary" @click="save">
          <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
          <span v-if="!isLoading">Simpan</span>
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import AktifitasMarketingRepository from '@/repositories/marketing/aktifitas-marketing-repository'
import TabPekerjaan from '@/views/pages/marketing/aktifitas-marketing/tabs/TabPekerjaan'
import TabCustomer from '@/views/pages/marketing/aktifitas-marketing/tabs/TabCustomer'
import TabAktifitas from '@/views/pages/marketing/aktifitas-marketing/tabs/TabAktifitas'
import moduleAktifitasMarketingAdd from '@/store/modules/marketing/aktifitas-marketing/aktifitas-marketing-add.store'
import { mapState } from 'vuex'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

export default {
  name: 'AktifitasMarketingEdit',
  components: {
    TabPekerjaan,
    TabCustomer,
    TabAktifitas
  },
  mounted () {
    this.listenKeyboardPressed()
    this.getInitData()
  },
  computed: {
    activeTab: {
      get () {
        return this.$store.state.marketing.aktifitasMarketingAdd.activeTab
      },
      set (value) {
        this.$store.commit('marketing/aktifitasMarketingAdd/SET_ACTIVE_TAB', value)
      }
    },
    ...mapState({
      errors: state => state.marketing.aktifitasMarketingAdd.errors,
      isAnyUnsavedChanges: state => state.marketing.aktifitasMarketingAdd.isAnyUnsavedChanges,
      dataTabAktifitas: state => state.marketing.aktifitasMarketingAdd.tabAktifitas,
      dataTabCustomer: state => state.marketing.aktifitasMarketingAdd.tabCustomer,
      dataTabPekerjaan: state => state.marketing.aktifitasMarketingAdd.tabPekerjaan
    })
  },
  data () {
    return {
      isLoading: false,
      initDataTabAktifitas: null,
      initDataTabCustomer: null,
      initDataTabPekerjaan: null
    }
  },
  methods: {
    getInitData () {
      this.isLoading = true

      const idCustomerProspek = this.$route.params.idCustomerProspek
      AktifitasMarketingRepository.show(idCustomerProspek)
        .then(response => {
          this.buildInitData(response.data.data)
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildInitData (initData) {
      // TAB AKTIFITAS
      const initDataTabAktifitas = _.pick(initData, ['id', 'no_bukti', 'id_staf', 'nama_staf', 'hub_prospek', 'tgl_mulai', 'tgl_selesai', 'status', 'keterangan', 'aktifitas'])
      initDataTabAktifitas.aktifitas = _.map(initDataTabAktifitas.aktifitas, item => {
        const additionalKeys = { uuid: uuid() }
        return _.extend({}, item, additionalKeys)
      })

      // TAB CUSTOMER
      const initDataTabCustomer = _.pick(initData, ['id_proyek', 'id_customer_prospek', 'kode_customer_prospek', 'customer'])

      // TAB PEKERJAAN
      const initDataTabPekerjaan = _.pick(initData, ['customer'])

      this.initDataTabAktifitas = initDataTabAktifitas
      this.initDataTabCustomer = initDataTabCustomer
      this.initDataTabPekerjaan = initDataTabPekerjaan
    },

    save () {
      this.$store.commit('marketing/aktifitasMarketingAdd/CLEAR_ERRORS')
      this.isLoading = true

      const params = this.buildParams()
      AktifitasMarketingRepository.update(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            const errors = error.response.data.errors
            this.$store.commit('marketing/aktifitasMarketingAdd/SET_ERRORS', errors)
            this.notifyError('Data tidak valid, mohon periksa kembali.')
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildParams () {
      // tab aktifitas get only needed key
      const tabAktifitas = _.pick(this.dataTabAktifitas, ['aktifitas', 'deletedIds'])

      // tab customer get only needed key
      const tabCustomer = _.pick(this.dataTabCustomer, ['id_proyek', 'id_customer_prospek'])
      tabCustomer.customer = _.pick(this.dataTabCustomer.customer, ['id', 'no_identitas', 'nama', 'gender', 'agama', 'hp', 'telp', 'email', 'kota', 'kelurahan', 'pos', 'alamat', 'tgl_lahir', 'tmp_lahir', 'tujuan_pembelian', 'sumber_dana', 'status_nikah', 'nama_pasangan', 'status_tmptgl'])

      // tab pekerjaan get only needed key
      const tabPekerjaan = {}
      tabPekerjaan.customer = _.pick(this.dataTabPekerjaan.customer, ['id_profesi', 'status_profesi', 'jabatan', 'npwp', 'alamat_npwp', 'alamat_surat', 'no_rekening', 'nama_instansi', 'telp_instansi', 'fax_instansi', 'cp_instansi', 'jenis_usaha', 'alamat_instansi', 'pengh_tetap', 'pengh_tambah', 'pengh_bulanan'])

      return { tabAktifitas, tabCustomer, tabPekerjaan }
    },

    onSuccess () {
      this.$store.commit('marketing/aktifitasMarketingAdd/SET_ANY_UNSAVED_CHANGES', false)
      this.notifySuccess('Data berhasil disimpan.')
      this.$router.push({ name: 'marketing.aktifitasMarketing' })
    },

    confirmLeaveOptions () {
      return {
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Ada perubahan yang belum disimpan, yakin ingin berpindah halaman?',
        acceptText: 'Yakin',
        cancelText: 'Batal'
      }
    },

    listenKeyboardPressed () {
      window.addEventListener('keypress', e => this.onKeyboardPressed())
    },

    onKeyboardPressed: _.debounce(function () {
      this.$store.commit('marketing/aktifitasMarketingAdd/SET_ANY_UNSAVED_CHANGES', true)
    }, 500)

  },
  beforeCreate () {
    this.$store.registerModule(['marketing', 'aktifitasMarketingAdd'], moduleAktifitasMarketingAdd)
  },
  beforeDestroy () {
    this.$store.commit('marketing/aktifitasMarketingAdd/RESET_STATE')
    this.$store.unregisterModule(['marketing', 'aktifitasMarketingAdd'])
  },
  beforeRouteLeave (to, from, next) {
    if (this.isAnyUnsavedChanges) {
      this.$vs.dialog({
        ...this.confirmLeaveOptions(),
        accept: () => {
          next()
        }
      })
      return
    }
    next()
  }
}
</script>
